import DesktopProposedTimesComponent from "./Desktop/DesktopProposedTimes.component";
import MobileProposedTimesComponent from "./Mobile/MobileProposedTimes.component";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  removeRequestDatetime,
  setBookingError,
  setPrimaryRequestSlot,
} from "store/dux/booking/booking.actions";
import { useNavigate, useParams } from "react-router-dom";
import useBusiness from "hooks/mentee/useBusiness";
import useMyProfile from "hooks/useMyProfile";
import useMentor from "hooks/useMentor";
import { PROFILE_TYPE } from "entities/Profile";
import { SessionRequestDto } from "store/dux/booking/booking.dto";
import { requestSession } from "store/dux/booking/booking.thunks";
import { useAuth0 } from "@auth0/auth0-react";

enum ERROR_MESSAGES {
  SLOT = "Please select a valid Session Time",
  DURATION = "Please select a valid Session Length",
  OKR = "Please select, or create a new OKR",
  DESCRIPTION = "Please add infomation about your problem or desired outcome.",
  TITLE = "Please add a Session title",
  COST = "You don't have enough credits remaining for this session.",
  SLOT_QUANTITY = "Please select at least four available date & times",
}

const ProposedTimes = () => {
  const { slug: mentor_slug } = useParams<Record<string, string | undefined>>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const booking = useAppSelector((state) => state.booking);
  const { business } = useBusiness();
  const { profile } = useMyProfile();
  const { mentor } = useMentor({ slug: mentor_slug });
  const { getAccessTokenSilently } = useAuth0();

  const onMarkPrimary = (datetime: string) => {
    console.log(datetime);
    dispatch(setPrimaryRequestSlot(datetime));
  };

  const onRemoveTime = (datetime: string) => {
    console.log(datetime);
    dispatch(removeRequestDatetime(datetime));
  };

  const {
    duration,
    mentee_request_slots,
    ideal_proposed_time,
    error_message,
    description,
    session_request_is_submitting,
  } = booking;

  const onRequestSession = () => {
    // build the payload
    if (session_request_is_submitting) {
      return;
    }

    if (profile.type !== PROFILE_TYPE.MENTEE) {
      throw Error("Mentors cannot book sessions!");
    }

    if (!mentor) {
      throw Error("Mentor hasn't loaded");
    }

    // validate the mentee_request_slots is greater than 3
    if (mentee_request_slots.length < 3) {
      dispatch(setBookingError(ERROR_MESSAGES.SLOT_QUANTITY));
      return;
    }

    // Don't throw an error just default to the first selected slot.
    if (ideal_proposed_time) {
      dispatch(setPrimaryRequestSlot(mentee_request_slots[0]));
    }

    const session_request: SessionRequestDto = {
      ideal_proposed_time,
      mentee_request_slots,
      duration,
      description,
      to: mentor?._id,
      from: String(profile._id),
    };

    const cost = (duration / 60) * mentor.hourlyRate.tokens;

    if (business && business.balance - cost < 0) {
      dispatch(setBookingError(ERROR_MESSAGES.COST));
      return;
    }

    if (session_request["description"].length === 0) {
      dispatch(setBookingError(ERROR_MESSAGES.DESCRIPTION));
      return;
    }

    // TODO: validate that the use has selected x number of date times...
    const goTo = () => navigate(`/profile/${mentor_slug}/book-session/success`);
    getAccessTokenSilently().then((token: string) =>
      dispatch(requestSession(token, session_request, goTo))
    );
  };

  return window.innerWidth > 900 ? (
    <DesktopProposedTimesComponent
      times={
        mentee_request_slots
          ? mentee_request_slots.map((slot) => ({
              datetime: slot,
              is_primary: slot == ideal_proposed_time,
            }))
          : []
      }
      is_submitting={session_request_is_submitting}
      error_message={error_message}
      onMarkPrimary={onMarkPrimary}
      onRemoveTime={onRemoveTime}
      onRequestSession={onRequestSession}
    />
  ) : (
    <MobileProposedTimesComponent
      times={
        mentee_request_slots
          ? mentee_request_slots.map((slot) => ({
              datetime: slot,
              is_primary: slot == ideal_proposed_time,
            }))
          : []
      }
      is_submitting={session_request_is_submitting}
      error_message={error_message}
      onMarkPrimary={onMarkPrimary}
      onRemoveTime={onRemoveTime}
      onRequestSession={onRequestSession}
    />
  );
};

export default ProposedTimes;
